import '/assets/styles/sections/header/header.scss';
import config from '/config';
import classNames from 'classnames';

// Deps
import { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import loadable from '@loadable/component';

// Context
import { useBasket } from '/controllers/basket';
import { useChildren } from '/utilities/children';
import { useAppNotifications } from '/utilities/app-notifications';
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';
import { useAuth } from '@wearetla/tla-essentials-tools/utilities/auth';
import { useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator'
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';
import { useHead } from '@wearetla/tla-essentials-tools/utilities/head';

import HeaderContext from './context/header-context'

// Partials
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Icon from '@wearetla/tla-essentials-tools/partials/icon';
import Btn from '/views/partials/btn';

// Services
import productServices from '/services/product'
// import userServices from '/services/user'
// import siteServices from '/services/site'

// Functions
import processAutocompleteResults from './functions/process-autocomplete-results';

// Data
import ageGroups from '/data/age-groups'
import childrenGenders from '/data/genders-children'

// Sections
const TopBanner = loadable(() => import('./sections/top-banner'));
const DesktopMenu = loadable(() => import('./sections/menu-desktop'));
const MobileSubnav = loadable(() => import('./sections/mobile-subnav'));
// const AgeMenu = loadable(() => import('./sections/age-menu'), { ssr: false });
const Search = loadable(() => import('./partials/search'));
const UserMenu = loadable(() => import('./sections/user-menu'), { ssr: false });
const ChildMenu = loadable(() => import('./sections/child-menu'), { ssr: false });
const MobileMenu = loadable(() => import('./sections/menu-mobile'), { ssr: false });
const MobileSearchMenu = loadable(() => import('./sections/mobile-searchmenu'), { ssr: false });

// Static Assets
import image_logo from '/assets/images/logo-anniversary.svg';

const Header = ({ plain }) => {
	const _mounted = useRef(false);
	const containerRef = useRef();
	const { isApplication, setData } = useGlobalState();
	const { notificationCount } = useAppNotifications();
	const { mobile } = useBreakpoints();
	const { summary: basketSummary } = useBasket();
	const headData = useHead();
	const { loggedIn, isGuest } = useAuth();
	const { openModal } = useModals();
	const { childSelection, /*setChildSelection,*/ children } = useChildren();
	const { activeRoute, getRoutePath, redirect } = useNavigator();

	const [showUserMenu, setShowUserMenu] = useState(false);
	const [showChildMenu, setShowChildMenu] = useState(false);
	// const [showAgeMenu, setShowAgeMenu] = useState(false);
	const [popularSearches, setPopularSearches] = useState(false);
	const [showMobileSearchMenu, setShowMobileSearchMenu] = useState(false);
	const [showMobileMenu, setShowMobileMenuRaw] = useState(false);

	const setShowMobileMenu = useCallback((state) => {
		setShowMobileMenuRaw(state);
	}, []);

	const appPlain = useMemo(() => {
		return isApplication && mobile && !activeRoute?.regularAppHeader;
	}, [isApplication, mobile, activeRoute])
	const plainHeadSearch = useMemo(() => !!activeRoute?.plainHeaderSearch, [activeRoute]);
	const plainHeadSharer = useMemo(() => !!activeRoute?.plainHeaderSharer, [activeRoute]);

	const childSelectionInfo = useMemo(() => {
		let infoFields = [];
		const child = (children && childSelection.id) ? children.find(c => c.id === childSelection.id) : false;
		let selectionGender = false;

		if(childSelection.gender) {
			selectionGender = childrenGenders.find(g => g.value === childSelection.gender);

			if(selectionGender) {
				infoFields.push(selectionGender.label)
			}
			else if(childSelection.gender === config.listingPregnantGender) {
				infoFields.push('Hamile');
				selectionGender = {
					value: config.listingPregnantGender,
					key: 'pregnant',
					label: 'Hamile',
				}
			}
		}
		if(childSelection.age) {
			const group = ageGroups.find(g => g.value === childSelection.age);

			if(group) {
				infoFields.push(group.label);
			}
		}

		if(infoFields.length) {
			return {
				title: child ? child.name : 'Bebeğim',
				text: infoFields.join(', '),
				gender: selectionGender ? selectionGender.key : 'baby',
			}
		}
		else {
			return false;
		}
	}, [childSelection, children]);

	const openChildMenu = () => {
		setShowChildMenu(true);
	}

	const goBack = (e, url, fallbackUrl) => {
		if(e && e.preventDefault) {
			e.preventDefault();
		}

		const checkMobileHistory = () => {
			return new Promise((resolve, reject) => {
				if(mobile && activeRoute?.key === 'category' && window.$babyMall?.mobileMenuHistory) {
					const msgEvent = new CustomEvent('showMobileMenuFromHistory');
					window.dispatchEvent(msgEvent);

					setTimeout(() => {
						resolve(true);
					}, 500)
				}
				else {
					resolve(true);
				}
			});
		}		

		checkMobileHistory().then(() => {
			if(url) {
				redirect(url);
			}
			else if(window.$babyMall?.prevLocation) {
				window.history.back();
			}
			else {
				redirect(fallbackUrl ? fallbackUrl : 'home');
			}

			if(isApplication && mobile) {
				setTimeout(() => {
					window.scrollTo(0, 1)
					window.scrollTo(0, 0)
				}, 100)
			}
		})
	}

	const toggleMobileMenu = useCallback(() => {
		setShowMobileMenu(!showMobileMenu);
	}, [showMobileMenu]);

	const toggleUserMenu = useCallback(() => {
		setShowUserMenu(!showUserMenu);
	}, [showUserMenu]);

	const sharePage = async () => {
		const link = window.location.href;
		try {
			await navigator.share({
				title: headData.data.title,
				url: link,
			});
		}
		catch (e) {
			if(e.name !== 'AbortError') {
				openModal('alert', {
					title: 'Ürünü paylaş',
					message: `"<span class="link smalltext">${link}</span>" <br />Adresini kopyalayarak ürünü paylaşabilirsiniz.`,
				})
			}
		}
	}


	const contextApi = useMemo(() => ({
		// Mobile Menu
		showMobileMenu, 
		setShowMobileMenu,
		toggleMobileMenu,
		// User Menu
		showUserMenu,
		setShowUserMenu,
		toggleUserMenu,
		// Mobile Search Menu
		showMobileSearchMenu,
		setShowMobileSearchMenu,
		// Child Menu
		showChildMenu,
		setShowChildMenu,
		// Popular
		popularSearches,
	}), [
		// Mobile Menu
		showMobileMenu, 
		setShowMobileMenu,
		toggleMobileMenu,
		// User Menu
		showUserMenu,
		setShowUserMenu,
		toggleUserMenu,
		// Mobile Search Menu
		showMobileSearchMenu,
		// Child Menu
		showChildMenu,
		setShowChildMenu,
		// Popular
		popularSearches,
	])

	useEffect(() => {
		if(popularSearches === false && !plain && !(appPlain && !plainHeadSearch)) {
			setPopularSearches(null);

			productServices.getPopularSearches().then((payload) => {
				if(_mounted.current) {
					setPopularSearches([
						{
							title: 'Popüler Aramalar',
							type: 'label',
							items: processAutocompleteResults(payload.keywords, 'keyword', false, getRoutePath)
						},
						{
							title: 'Popüler Ürünler',
							type: 'regular',
							items: processAutocompleteResults(payload.products, 'product', false, getRoutePath)
						},
					]);
				}
			}).catch(() => {
				if(_mounted.current) {
					setPopularSearches([]);
				}
			});
		}
	}, [popularSearches, plain, plainHeadSearch, appPlain]);

	useEffect(() => {
		if(mobile) {
			setData('topViewportOffset', containerRef.current?.offsetHeight ?? 0);
		}
		else {
			setData('topViewportOffset', 0);
		}
	}, [isApplication, mobile])

	useEffect(() => {
		_mounted.current = true;
		window.addEventListener('openChildMenu', openChildMenu, false);

		return () => {
			window.removeEventListener('openChildMenu', openChildMenu, false);
			_mounted.current = false;
		}
	}, [])

	return (
		<HeaderContext.Provider value={contextApi}>
			<header
				ref={containerRef}
				className={classNames('section header', { plain, 'app-plain': appPlain, 'type-app': isApplication})}>
				<TopBanner />

				{(!isApplication && !appPlain && !plain) &&
					<nav className="header-topmenu">
						<div className="topmenu-wrap wrapper">
							{!mobile &&
								<>
									{/* <button
										type="button"
										onMouseLeave={() => { if(_mounted.current) { setShowAgeMenu(false); } }}
										onMouseEnter={() => { if(_mounted.current) { setShowAgeMenu(true); } }}
										className="topmenu-item highlight age">
										Yaşa Göre
									</button> */}
									{config.customLists?.internetProducts?.linkParams &&
										<Link
											{...config.customLists?.internetProducts.linkParams}
											className="topmenu-item highlight internet">
											İnternete Özel
										</Link>
									}
									{config.customLists?.outletProducts?.linkParams &&
										<Link
											{...config.customLists?.outletProducts.linkParams}
											className="topmenu-item highlight">
											Outlet
										</Link>
									}
								</>
							}
							<Link
								href="campaigns"
								className="topmenu-item highlight">
								Kampanyalar
							</Link>
							<Link
								href="stores"
								className="topmenu-item">
								Mağazalarımız
							</Link>
							<Link
								href="faq"
								className="topmenu-item">
								Yardım
							</Link>

							{/* {!mobile &&
								<AgeMenu
									show={showAgeMenu}
									onMouseEnter={() => { if(_mounted.current) { setShowAgeMenu(true); } }}
									onMouseLeave={() => { if(_mounted.current) { setShowAgeMenu(false); } }} />
							} */}
						</div>
					</nav>
				}

				<div className="wrapper header-wrap">
					{!appPlain ?
						<>
							<div className="header-main">
								{!plain ?
									<div className="main-aside search">
										<Search className="aside-item aside-search" />
									</div>
									:
									<>
										<div className="main-aside backbtn">
											<Btn
												data-gtm-id={activeRoute?.key === 'checkout' ? 'goBackFromCheckout' : (activeRoute?.key === 'basket' ? 'goBackFromBasket' : undefined)}
												className="small outline"
												tag="link"
												href={activeRoute?.plainHeaderBackBtn ? activeRoute?.plainHeaderBackBtn : 'home'}
												onClick={(e) => {
													goBack(e, activeRoute?.plainHeaderBackBtn, activeRoute?.plainHeaderBackBtnFallback);
												}}
												icon="arrow-left">
												alışverişe devam et
											</Btn>
										</div>
									</>
								}
								<Link title="Ana Sayfa" href="home" className="main-logo">
									<Img src={image_logo} className="logo-image" />
								</Link>
								<div className="main-aside controls">
									{!plain ?
										<>
											<div className="aside-item">
												<div className="main-childselection">
													<Btn
														title="Bebek Seçimi"
														className={
															'childselection-button blue-bg' + (childSelectionInfo ? ' has-selection' : '') +
															(childSelectionInfo && childSelectionInfo.gender ? ` ${childSelectionInfo.gender}` : '')
														}
														afterIcon="angle-down"
														onClick={() => { if(_mounted.current) { setShowChildMenu(!showChildMenu); }}}>
														<Icon name={childSelectionInfo ? childSelectionInfo.gender : 'baby'} className="button-icon" />
														<p className="button-text">
															{childSelectionInfo ?
																<>
																	{childSelectionInfo.title}
																	<span className="text-selection">
																		{childSelectionInfo.text}
																	</span>
																</>
																:
																<>Bebeğim</>
															}
														</p>
													</Btn>
													{!mobile &&
														<ChildMenu />
													}
												</div>
											</div>
											{isApplication &&
												<div className="aside-item">
													<button
														type="button"
														title="Bildirimler"
														onClick={() => {
															openModal('notifications');
														}}
														className="aside-btn">
														{(notificationCount > 0) &&
															<span className="btn-count">{notificationCount}</span>
														}
														<Icon name="notification" className="btn-icon" />
													</button>
												</div>
											}
											{!mobile &&
												<>
													{(loggedIn) ?
														<>
															<div
																className="aside-item"
																onClick={() => {
																	setShowUserMenu(false);
																}}
																onMouseEnter={() => { 
																	setShowUserMenu(true);
																}}
																onMouseLeave={() => {	
																	setShowUserMenu(false);
																}}>
																<Link
																	title="Hesabım"
																	{...(isGuest ? {
																		raw: true,
																		href: '#',
																	}: {
																		href: "account"
																	})}
																	className={classNames('aside-btn user', { active: loggedIn })}>
																	<Icon name="account" className="btn-icon" />
																</Link>
																<UserMenu />
															</div>
														</>
														:
														<div className="aside-item">
															<Link
																title="Giriş Yap"
																href="login"
																className="aside-btn">
																<Icon className="btn-icon" name="account" />
															</Link>
														</div>
													}
													<div className="aside-item">
														<Link
															title="Sepet"
															href="basket"
															className="aside-btn main-basketbtn">
															<span className="btn-count">{basketSummary.basket_quantity > 9 ? <>9<span>+</span></> : basketSummary.basket_quantity}</span>
															<Icon className="btn-icon" name="cart" />
														</Link>
													</div>
												</>
											}
										</>
										:
										<>
											<div className="main-secure">
												Güvenli Alışveriş
												<Icon className="secure-icon" name="secure" />
											</div>
										</>
									}
								</div>
							</div>
							{!plain && !mobile &&
								<DesktopMenu />
							}
						</>
						:
						<div className={`header-app-plainheader${(plainHeadSearch || plainHeadSharer) ? ' has-sidebtn' : ''}`}>
							{activeRoute.plainHeaderBackBtn !== false &&
								<Link
									data-gtm-id={activeRoute.key === 'checkout' ? 'goBackFromCheckout' : (activeRoute.key === 'basket' ? 'goBackFromBasket' : undefined)}
									href={activeRoute.plainHeaderBackBtn ? activeRoute.plainHeaderBackBtn : 'home'}
									onClick={(e) => {
										goBack(e, activeRoute.plainHeaderBackBtn, activeRoute.plainHeaderBackBtnFallback);
									}}
									className="plainheader-backbtn">
									<Icon name="arrow-left" />
								</Link>
							}
							<div className="plainheader-text">
								{(headData?.data?.title && headData?.data?.loaded !== false) ?
									<>
										<h1 className={`plainheader-title${headData.data.subtitle ? ' has-subtitle' : ''}`}>{headData.data.title}</h1>
										{headData.data.subtitle &&
											<span className="title-subtitle">{headData.data.subtitle}</span>
										}
									</>
									:
									<h1 className="plainheader-title">{activeRoute.loadingTitle ? activeRoute.loadingTitle : 'Yükleniyor...'}</h1>
								}
							</div>
							{plainHeadSearch &&
								<button
									className="plainheader-sidebtn search"
									onClick={() => {
										setShowMobileSearchMenu(true);
									}}
									type="button">
									<Icon name="search" />
								</button>
							}
							{(plainHeadSharer && !plainHeadSearch) &&
								<button
									className="plainheader-sidebtn share"
									onClick={sharePage}
									type="button">
									<Icon name="share" />
								</button>
							}
						</div>
					}
				</div>
			</header>
			{mobile &&
				<>
					{(isApplication && mobile) &&
						<div className={`section header-space-placeholder ${appPlain ? ' plain' : ''}`}></div>
					}
					<MobileMenu />
					<ChildMenu />
					<UserMenu />
					<MobileSearchMenu />
					{!(isApplication && activeRoute?.key === 'productDetail') &&
						<MobileSubnav />
					}
				</>
			}
		</HeaderContext.Provider>
	)
}

export default Header;
