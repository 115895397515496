import kebabCase from 'lodash/kebabCase';

const processAutocompleteResults = (results, type, searchText, getRoutePath) => (results.map(r => {
	var searchRegexp = searchText ? new RegExp(searchText.current.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), "gi") : false;

	let href = undefined;
	let typeText = false;

	if(type === 'product') {
		typeText = 'Ürün';
		href = getRoutePath('productDetail', { id: r.productid, slug: r.slug })
	}
	else if(type === 'category') {
		typeText = 'Kategori';
		href = getRoutePath('category', { id: r.id, slug: r.slug });
	}
	else if(type === 'brand') {
		typeText = 'Marka';
		href = getRoutePath('brand', { id: r.id, slug: kebabCase(r.name) });
	}
	else if(type === 'keyword') {
		return {
			name: searchRegexp ? r.replace(searchRegexp, "<strong>$&</strong>") : r,
			type: 'Arama Kelimesi',
			href: href = getRoutePath('search', { search: r }),
		}
	}

	return {
		...r,
		name: searchRegexp ? r.name.replace(searchRegexp, "<strong>$&</strong>") : r.name,
		type: typeText,
		href: href,
	};
}));

export default processAutocompleteResults;