const popularLinks = {
	categories: {
		title: 'Popüler Kategoriler',
		links: [
			{
				title: 'Oyun Parkı ve Park Yataklar',
				href: '/oyun-parki-ve-park-yataklar-c-18'
			},
			{
				title: 'Bebek Arabaları',
				href: '/bebek-arabasi-c-377'
			},
			{
				title: 'Bebek Banyo Ürünleri',
				href: '/banyo-urunleri-c-36'
			},
			{
				title: 'Ev İçi Güvenlik Ürünleri',
				href: '/ev-ici-guvenlik-urunleri-c-37'
			},
			{
				title: 'Bebek Bezi',
				href: '/bebek-bezleri-c-26'
			},
			{
				title: 'Ana Kucağı',
				href: '/ana-kucagi-c-91'
			},
			{
				title: 'Yürüteçler',
				href: '/yurutecler-c-95'
			},
			{
				title: 'Scooterlar',
				href: '/scooterlar-c-273'
			},
			{
				title: 'Bebek Beşikleri',
				href: '/bebek-besikleri-c-64'
			},
			{
				title: 'Bebek Nevresim Takımları',
				href: '/nevresim-takimlari-c-225'
			},
			{
				title: 'Akülü Araba',
				href: '/akulu-arabalar-c-265'
			},
			{
				title: 'Ek Gıdalar',
				href: '/oyun-hamurlari-c-305'
			},
			{
				title: 'Mama Sandalyeleri',
				href: '/mama-sandalyeleri-c-17'
			},
			{
				title: 'Eğitici Oyuncaklar',
				href: '/egitici-oyuncaklar-c-57'
			},
			{
				title: 'Kırtasiye',
				href: '/kirtasiye-c-63'
			},
			{
				title: 'Deniz ve Havuz Ürünleri',
				href: '/deniz-havuz-urunleri-c-62'
			},
			{
				title: 'Bebek Tuvalet Eğitimi',
				href: '/tuvalet-egitimi-c-35'
			},
			{
				title: 'Biberon ve Emzikler',
				href: '/biberon-emzikler-c-24'
			},
			{
				title: 'Mamalar',
				href: '/bebek-mamalari-c-20'
			},
		]
	},
	carts: {
		title: 'Popüler Bebek Arabaları',
		links: [
			{
				title: 'Prego 2073 Escalade Travel Sistem Bebek Arabası Gri',
				href: '/prego-2073-escalade-travel-sistem-bebek-arabasi-gri-p-31862'
			},
			{
				title: 'Kraft Energy Travel Sistem Bebek Arabası Light Grey',
				href: '/kraft-energy-travel-sistem-bebek-arabasi-light-grey-p-31836'
			},
			{
				title: 'Chicco We Bebek Arabası Black',
				href: '/chicco-we-bebek-arabasi-black-p-32040'
			},
			{
				title: 'Duux Siena Baston Bebek Arabası Gri',
				href: '/duux-siena-baston-bebek-arabasi-gri-p-16198'
			},
			{
				title: 'Duux Derby Cabin Bebek Arabası Gold Kahve',
				href: '/duux-derby-cabin-bebek-arabasi-gold-kahve-p-34648'
			},
		]
	},
	seats: {
		title: 'Popüler Oto Koltukları',
		links: [
			{
				title: 'Avova Star Fix Oto Koltuğu 15-36 Atlantic Blue',
				href: '/avova-star-fix-oto-koltugu-15-36-kg-atlantic-blue-p-34292'
			},
			{
				title: 'Chicco Fold Go I-Size Air Oto Koltuğu16-36 Kg Ink Air',
				href: '/chicco-chicco-fold-go-i-size-air-oto-koltugu-15-36-kg-ink-air-p-32456'
			},
			{
				title: 'Peg Perego Via Viaggio Oto Koltuğu 9-36 Kg Licorice',
				href: '/peg-perego-peg-perego-via-viaggio-oto-koltugu-9-36-kg-licorice-p-11293'
			},
			{
				title: 'Kraft Techno Signal 9-36 Kg Oto KoltuğuBeige',
				href: '/kraft-techno-signal-9-36-kg-oto-koltugu-beige-p-33340 '
			},
			{
				title: 'Maxi Cosi Tobi Oto Koltuğu 9-18 Kg Authe Graphite',
				href: '/maxi-cosi-tobi-oto-koltugu-9-18-kg-authe-graphite-p-24813'
			}
		]
	},
	products: {
		title: 'Popüler Ürünler',
		links: [
			{
				title: 'Mochi Manuel GöğüsPompası',
				href: '/mochi-manuel-gogus-pompasi-p-26102'
			},
			{
				title: 'Duux Mami Pedli Mama Sandalyesi Gri',
				href: '/duux-mami-pedli-mama-sandalyesi-gri-p-26379'
			},
			{
				title: 'Weewell Wym400 Dijital Yoğurt Yapma Makinesi',
				href: '/weewell-wym400-dijital-yogurt-yapma-makinesi-p-13859'
			},
			{
				title: 'Hünnap Keçi Sütlü Bebek Bisküvisi 400 Gr',
				href: '/hunnap-keci-sutlu-bebek-biskuvisi-400-gr-p-24839'
			},
			{
				title: 'Sulasil Sıkıştırılmış Bebek Mendili 500Lü',
				href: '/sulasil-sikistirilmis-bebek-mendili-500lu-p-33991'
			},
			{
				title: 'Eğitici Matkap Seti Hayvanlar 3D Stem Creative Unisex',
				href: '/egitici-matkap-seti-hayvanlar-3d-stem-creative-unisex-p-32464'
			},
			{
				title: 'Babyjem Katlanabilen Bebek Banyo Seti 3Lü Gri',
				href: '/babyjem-katlanabilen-bebek-banyo-seti-3lu-gri-p-34973'
			},
			{
				title: 'Loobex Dt-4060 KızılötesiTemassızAteşÖlçer',
				href: '/loobex-dt-4060-kizilotesi-temassiz-ates-olcer-p-33411'
			},
			{
				title: 'Moi Noi Kanguru Cepli Bebek Yazlıkşortlu Tulum ',
				href: '/moi-noi-kanguru-cepli-bebek-yazlik-sortlu-tulum-mint-p-31189/9-12-ay/88202'
			},
			{
				title: 'Pilsan Katlanabilir Top Havuzu Ve 200 Adet Top',
				href: '/pilsan-pilsan-katlanabilir-top-havuzu-ve-200-adet-top-p-13214'
			},
			{
				title: 'Prego Yatak Bariyeri 60x150 Gri',
				href: '/prego-yatak-bariyeri-60x150-gri-p-33601'
			},
		]
	},
	brands: {
		title: 'Popüler Markalar',
		links: [
			{
				title: 'BM CARE',
				href: 'bm-care-m-29757',
			},
			{
				title: 'Kraft',
				href: 'kraft-m-27',
			},
			{
				title: 'Fisher Price',
				href: 'fisher-price-m-71',
			},
			{
				title: 'Kanz',
				href: 'kanz-m-1',
			},
			{
				title: 'Lansinoh',
				href: 'lansinoh-m-80',
			},
			{
				title: 'Kikka Boo',
				href: 'kikka-boo-m-29709',
			},
			{
				title: 'Duux',
				href: 'duux-m-29554',
			},
			{
				title: 'Pierre Cardin',
				href: 'pierre-cardin-m-1307',
			},
			{
				title: 'Globber',
				href: 'globber-m-29669',
			},
			{
				title: 'Avova',
				href: 'avova-m-29607',
			},
			{
				title: 'Lego',
				href: 'lego-m-29665',
			},
			{
				title: 'OiOi',
				href: 'oioi-m-101',
			},
			{
				title: 'Inglesina',
				href: 'inglesina-m-62',
			},
			{
				title: 'Doona',
				href: 'doona-m-142',
			},
			{
				title: 'Babyjem',
				href: 'babyjem-m-29589',
			},
			{
				title: 'Besafe ',
				href: 'besafe-m-177',
			},
			{
				title: 'Philips Avent',
				href: 'philips-avent-m-91',
			},
			{
				title: 'Mochi',
				href: 'mochi-m-259',
			},
			{
				title: 'Maxi-Cosi',
				href: 'maxi-cosi-m-39',
			},
			{
				title: 'Pilsan',
				href: 'pilsan-m-40',
			},
			{
				title: 'Dolu',
				href: 'dolu-m-86',
			}
		]
	},
}

export default popularLinks;